import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoClose } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import logo from "../assets/logo/logo.png";
import { MdAccountCircle } from "react-icons/md";

function Navbar() {
    const [isVisible, setIsVisible] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuTransition, setMenuTransition] = useState(false);
    const [hideLink, setHideLink] = useState(false);
    const [negativeClass, setNegativeClass] = useState('text-negative');

    const location = useLocation();
    const isActive = (path) => location.pathname === path;

    let lastScrollY = window.scrollY;
    
    useEffect(() => {
        const handleScroll = () => {
            const isSmallDevice = window.innerWidth < 768;
            if (!isMenuOpen) {
                setIsVisible(window.scrollY < lastScrollY);
                lastScrollY = window.scrollY;
            }

            if (window.scrollY < 800) {
                setIsVisible(true)
            }
            
            if ((window.scrollY > 850 && !isSmallDevice) || (window.scrollY > 710 && isSmallDevice)) {
                setNegativeClass('text-negative');
            } else {
                // setNegativeClass('text-negative-remove');
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMenuOpen]);

    useEffect(() => {
        document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
    }, [isMenuOpen]);

    const handleMenuOpen = () => {
        setNegativeClass('text-negative-remove');
        setIsMenuOpen(true);
        setHideLink(false);
        setTimeout(() => {
            setMenuTransition(true);
        }, 1000);
    };

    const handleMenuClose = () => {
        setHideLink(true);
        setTimeout(() => {
            setMenuTransition(false);
            setIsMenuOpen(false);
        }, 900);
        setTimeout(() => {
            setNegativeClass('text-negative');
        }, 2000);
    };

    return (
    <>
        <div className={`text-negative-remove fixed z-50 top-0 left-0 w-full transition-transform duration-300 ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}>
            <div className={`flex justify-between items-center ${negativeClass === 'text-negative-remove' ? 'text-slate-50' : ''} p-8 md:p-5`}>
                <img src={logo} alt="logo careisgold" className='w-32 md:w-56 z-40'/>
            </div>
        </div>

        <div className={`${negativeClass} fixed z-50 top-0 left-0 w-full transition-transform duration-300 ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}>
            <div className={`flex justify-between items-center ${negativeClass === 'text-negative-remove' ? 'text-slate-50' : ''} p-10`}>
                <Link to="/">
                    <div className={`text-negative-remove fixed top-0 left-0 w-1/2 sm:w-1/4 transition-transform duration-300 ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}>
                        <div className={`flex justify-between items-center p-10 md:p-12`}>
                            <div className='w-32 md:w-56'/>
                        </div>
                    </div>
                </Link>
                <div className="hidden xl:block">
                    <nav className="flex justify-end items-center">
                        <Link to="/" className={`uppercase mx-6 ${isActive('/') ? 'underline-animate-active' : 'underline-animate'}`}>Home</Link>
                        <Link to="/reclami/" className={`uppercase mx-6 ${isActive('/reclami/') ? 'underline-animate-active' : 'underline-animate'}`}>Reclami</Link>
                        {/* <Link to="/prodotti-e-servizi/" className={`uppercase mx-6 ${isActive('/prodotti-e-servizi/') ? 'underline-animate-active' : 'underline-animate'}`}>Prodotti</Link>
                        <Link to="/notizie/" className={`uppercase mx-6 ${isActive('/notizie/') ? 'underline-animate-active' : 'underline-animate'}`}>Notizie</Link>
                        <Link to="/collabora-con-noi/" className={`uppercase mx-6 ${isActive('/collabora-con-noi/') ? 'underline-animate-active' : 'underline-animate'}`}>Collabora con noi</Link>
                        <Link to="/faq/" className={`uppercase mx-6 ${isActive('/faq/') ? 'underline-animate-active' : 'underline-animate'}`}>FAQ</Link>
                        <Link to="/contatti/" className={`uppercase mx-6 ${isActive('/contatti/') ? 'underline-animate-active' : 'underline-animate'}`}>Contatti</Link>*/}
                        <a target="_blank" href="https://crm.careholding.it/login.aspx" className={`uppercase ms-3 me-6 ${isActive('/area-clienti-collaboratori/') ? '' : ''}`}><MdAccountCircle className='text-3xl'/></a> 
                    </nav>
                </div>
                <div className="block xl:hidden">
                    <button className="w-6 h-6" onClick={handleMenuOpen}>
                        <div className=''><RxHamburgerMenu className='text-3xl text-gold'/></div>
                    </button>
                    <nav className={` z-50 flex flex-col justify-top items-center absolute top-0 right-0 w-full h-full bg-white transition-all duration-[1000ms] ease-in ${isMenuOpen ? 'opacity-100' : 'opacity-0'} ${isMenuOpen ? 'translate-y-0' : '-translate-y-full'} ${isMenuOpen && 'h-screen'}`}>
                        {menuTransition && (
                            <>
                                <Link to="/" data-aos="fade-right" data-aos-duration="250" className={`${hideLink ? 'link-hidden' : ''} text-black uppercase mx-6 mb-2 mt-36 ${isActive('/') ? 'text-gold' : ''}`}>Home</Link>
                                <Link to="/reclami/" data-aos="fade-right" data-aos-duration="500" className={`${hideLink ? 'link-hidden' : ''} text-black uppercase mx-6 my-2 ${isActive('/reclami/') ? 'text-gold' : ''}`}>Reclami</Link>
                                {/* <Link to="/prodotti-e-servizi/" data-aos="fade-right" data-aos-duration="750" className={`${hideLink ? 'link-hidden' : ''} text-black uppercase mx-6 my-2 ${isActive('/prodotti-e-servizi/') ? 'text-gold' : ''}`}>Prodotti</Link>
                                <Link to="/notizie/" data-aos="fade-right" data-aos-duration="1000" className={`${hideLink ? 'link-hidden' : ''} text-black uppercase mx-6 my-2 ${isActive('/notizie/') ? 'text-gold' : ''}`}>Notizie</Link>
                                <Link to="/collabora-con-noi/" data-aos="fade-right" data-aos-duration="1250" className={`${hideLink ? 'link-hidden' : ''} text-black uppercase mx-6 my-2 ${isActive('/collabora-con-noi/') ? 'text-gold' : ''}`}>Collabora con noi</Link>
                                <Link to="/faq/" data-aos="fade-right" data-aos-duration="1500" className={`${hideLink ? 'link-hidden' : ''} text-black uppercase mx-6 my-2 ${isActive('/faq/') ? 'text-gold' : ''}`}>FAQ</Link>
                                <Link to="/contatti/" data-aos="fade-right" data-aos-duration="1750" className={`${hideLink ? 'link-hidden' : ''} text-black uppercase mx-6 my-2 ${isActive('/contatti/') ? 'text-gold' : ''}`}>Contatti</Link> */}
                                <a target="_blank" href="https://crm.careholding.it/login.aspx" data-aos="fade-right" data-aos-duration="1750" className={`${hideLink ? 'link-hidden' : ''} text-black uppercase mx-6 my-2 ${isActive('/area-clienti-collaboratori/') ? 'text-gold' : ''}`}>Login</a>
                                <button className="absolute top-0 right-0 w-6 h-6 text-white m-10" onClick={handleMenuClose}>
                                    <IoClose className='text-3xl text-gold'/>
                                </button>
                            </>
                        )}
                    </nav>
                </div>
            </div>
        </div>
    </>
    );
}

export default Navbar;
