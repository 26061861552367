import React, { useEffect, useRef } from 'react';
import logo from '../assets/logo/logo.png';



function Footer({banner}) {
  const lineRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Trigger when 50% of the element is in viewport
    };
  
    const lineElement = lineRef.current; // Store the current element reference
    const elements = document.querySelectorAll('.animation-footer-text');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Add animation class when element is in viewport
          lineElement.classList.add('animated-line');
          elements.forEach(element => {
            element.classList.remove('opacity-0');
            element.classList.add('opacity-1');

          });

        } else {
          // Remove animation class when element is out of viewport
          lineElement.classList.remove('animated-line');
          elements.forEach(element => {
            element.classList.remove('opacity-1');
            element.classList.add('opacity-0');
          });
        }
      });
    }, options);
  
    // Start observing the line element
    observer.observe(lineElement);
  
    // Clean up observer when component unmounts
    return () => {
      // Check if the lineElement is still valid before unobserving
      if (lineElement) {
        observer.unobserve(lineElement);
      }
    };
  }, []);



  return (
    <>
      <footer className="relative lg:w-full bg-black text-white lg:py-20 py-10 px-5">
          <img src={logo} alt="logo careisgold" className="lg:absolute me-auto mb-10 lg:w-1/6 sm:w-1/3 w-1/2" />
        <div className="w-full flex justify-start lg:justify-center items-center z-20">
          <div className={`text-start lg:w-1/2`}>
              <p className={`lg:text-2xl text-lg font-semibold mb-1`}>CARE FIDELITY SRL Unipersonale</p>
              <p className={`text-sm lg:text- mb-2`}>L'azienda è soggetta alla vigilanza IVASS (www.ivass.it)</p>
              <p className={`text-sm lg:text-lg`}>Sede legale: VIA MONTE BALDO 14/D 37069 VILLAFRANCA DI VERONA</p>
              <p className={`text-sm lg:text-lg`}>Sede operativa: VIA MONTE BALDO 14/D 37069 VILLAFRANCA DI VERONA</p>
              <p className={`text-sm lg:text-lg`}>N° Iscrizione. RUI/Sez. A: A000396928</p>
              <p className={`text-sm lg:text-lg`}>Data iscrizione: 16 ottobre 2019</p>
              <p className={`text-sm lg:text-lg`}>Per consultare il registro <a className='text-blue-500' href="https://ruipubblico.ivass.it/rui-pubblica/ng/#/home" target="">clicca qui</a></p>
              <p className={`text-sm lg:text-lg`}>Tel. <a className='text-blue-500' href='tel:0458213155'>045 8213155</a> - Fax 045 7156029</p>
              <p className={`text-sm lg:text-lg`}>E-mail: <a className='text-blue-500' href='mailto:info@carefidelity.it'>info@carefidelity.it</a></p>
              <p className={`text-sm lg:text-lg`}>PEC: <a className='text-blue-500' href='mailto:carefidelitysrl@pec.carefidelity.it'>carefidelitysrl@pec.carefidelity.it</a></p>
              <p className={`text-sm lg:text-lg`}>E-mail: <a className='text-blue-500' href='mailto:reclami@carefidelity.it'>reclami@carefidelity.it</a></p>
              <p className={`text-sm lg:text-lg`}>C.F. e P.I. 04055940235 - REA VR-388182</p>
              <p className={`text-sm lg:text-lg`}>CAP. SOCIALE € 50.000 i.v.</p>

              <hr ref={lineRef} className="border-b border-gray-300 my-4" />

              <div className="flex flex-wrap justify-start lg:space-x-8">
                <p>CARE HOLDING SPA Via Monte Baldo, 14/D 37069 Villafranca di Verona (VR) P.Iva. 03211180132 - REA: VR-390429 CAP. SOCIALE € 300.000,00 i.v. - <a className='text-blue-500' href='mailto:info@careholding.it'>info@careholding.it</a> - <a className='text-blue-500' href='mailto:careholdingspa@pec.it'>careholdingspa@pec.it</a></p>
              </div>                
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
