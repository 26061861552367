import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

function Reclami() {

  return (
    <div>
      <Navbar />
      <div className="bg-[#fafafa] flex justify-center items-center pt-36 pb-10 px-10">
        <div className="w-full xl:w-1/2 text-center text-gray-700 font-medium text-lg">
          <p className="text-4xl font-semibold text-black mt-10 mb-5 uppercase">
            RECLAMI
          </p>
          <hr className="my-10"></hr>
          <p>
            Relativamente al rapporto contrattuale e la gestione dei sinistri il
            contraente ha la facoltà, ferma restando la possibilità di
            rivolgersi all’autorità giudiziaria, di inoltrare reclamo per
            iscritto all’impresa; qualora non dovesse ritenersi soddisfatto
            dell’esito del reclamo o in caso di assenza di riscontro da parte
            dell’impresa entro il termine massimo di quarantacinque giorni, può
            rivolgersi all’Istituto per la Vigilanza sulle Assicurazioni IVASS –
            Servizio Tutela Utenti – Via del Quirinale,21 – 00187 Roma,
            allegando la documentazione relativa al reclamo trattato
            dall’Impresa. Il Contraente, l’assicurato o comunque l’avente
            diritto ha la facoltà di proporre reclamo per scritto all’Agenzia
            reclami@carefidelity.it. In aggiunta, il Contraente può presentare
            reclamo alle compagnie mandanti, come da allegato 4 "Informazioni da
            rendere al contraente"
          </p>
          <hr className="my-10"></hr>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Reclami;
