import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation  } from 'react-router-dom';
import './App.css';
import HomePage from './pages/Home/HomePage';
import Lottie from "lottie-react";
import loading from "./assets/lottie/loding.json";
import Reclami from './pages/Reclami/Reclami.jsx';


function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);  // This effect will run every time the location changes

  return null; // This component does not render anything
}


function App() {

  const [rightClickEnabled, setRightClickEnabled] = useState(false);


  useEffect(() => {
    const handleContextMenu = (event) => {
      if (!rightClickEnabled) {
        event.preventDefault();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'p') {
        setRightClickEnabled(!rightClickEnabled);
      }
    };

    window.addEventListener('contextmenu', handleContextMenu);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [rightClickEnabled]); 


  return (
    <div className="App">
      <div id="loading-overlay">
        <div id="lottie-container">
          <Lottie animationData={loading} loop={true} />
        </div>
      </div>
      <Router>
      <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/reclami/" element={<Reclami />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
