import React, { useRef } from "react";
import headerImage from "../../assets/homepage/slide-bg01.jpg";
import { ScrollParallax } from "react-just-parallax";
import { SlArrowDown } from "react-icons/sl";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

function HomePage() {
  const nextSectionRef = useRef(null);

  const scrollToNextSection = () => {
    nextSectionRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <div>
      <Navbar />
      <div className="relative z-20 h-lvh overflow-hidden bg-black">
        <ScrollParallax
          enableOnTouchDevice={true}
          isAbsolutelyPositioned={true}
          lerpEase="0.05"
        >
          <img
            src={headerImage}
            alt="background"
            className="absolute top-0 left-0 w-full h-full object-cover"
            style={{ transform: "scale(1.2)" }}
          />
        </ScrollParallax>
        <div className="absolute top-0 left-0 bg-black bg-opacity-0 md:bg-opacity-0 w-full h-full flex justify-center">
          {/* Adjust the image to be more to the right and cover 100vh without stretching */}
          {/* <img src={logoTransp} className='opacity-20 w-2/3 hidden lg:block' style={{ position: 'absolute', right: '-30vh', height: '150vh', objectFit: 'contain' }} alt='logo careisgold' /> */}
          <div
            className="absolute bottom-0 left-0 text-left pb-56 px-8 md:p-32"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <h2 className="text-4xl md:text-6xl text-white font-semibold drop-shadow-2xl">
              CARE FIDELITY
              <br />
              LIBERI DI "R"ASSICURARTI
            </h2>
          </div>
          <SlArrowDown
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-duration="1000"
            onClick={scrollToNextSection}
            className="arrow cursor-pointer block absolute transform-translate-x-1/2 text-slate-50 text-3xl md:text-5xl"
          />
        </div>
      </div>
      <div ref={nextSectionRef}></div>
      {/* section start */}
      <div className="bg-[#fafafa] flex justify-center items-center pt-36 pb-10 px-10">
        <div className="w-full xl:w-1/2 text-center text-gray-700 font-medium text-lg">
          <p className="underline mb-2">
            Con sentenza del 31 gennaio 2025, il tribunale distrettuale di Lussemburgo ha dichiarato la FWU Life InsUrance Lux S.A. in liquidazione.
          </p>
          <p className="pb-5">La stessa sentenza ha nominato liquidatore il signor Yann Baden. Sarà responsabile dell'organizzazione della liquidazione.</p>
          <p className="mb-1"><span className="underline font-bold text-black">La liquidazione non comporta la perdita totale del risparmio</span> per gli assicurati (o, eventualmente, per i loro beneficiari).
          </p>
          <p className="mb-5">Entro sei mesi dalla sentenza, il liquidatore invierà una dichiarazione di credito precompilata a tutti gli assicurati (o, se del caso, ai loro beneficiari). Questi saranno informati di tutti i dettagli pratici in tale lettera.
          </p>
          <p className="underline">Gli assicurati dovranno inviare al liquidatore una dichiarazione di credito <b className="text-black">entro il 31 gennaio 2028</b>, anche se ciò non esclude <b className="text-black">la possibilità che i primi rimborsi avvengano prima di tale data</b>.</p>
          <p className="mb-5">Il controvalore degli attivi rappresentativi sarà destinato con preferenza al rimborso dei risparmi degli assicurati, in modo equo.
          </p>
          <a className="text-blue-700" href="https://www.caa.lu/fr/consommateurs/insolvabilite-de-fwu-life-insurance-lux-sa" target="_blank" rel="noopener noreferrer">https://www.caa.lu/fr/consommateurs/insolvabilite-de-fwu-life-insurance-lux-sa</a>
          <hr className="my-10"></hr>




          <p>
            Si informa che la compagnia FWU Life Insurance Lux S.A. ha{" "}
            <b className="text-black">sospeso la commercializzazione</b> dei
          </p>
          <p>
            propri prodotti a partire{" "}
            <b className="text-black">dal 03/07/2024.</b>
          </p>
          <p className="mt-10">
            Il Tribunale lussemburghese, in data{" "}
            <b className="text-black">02/08/2024,</b> ha approvato la richiesta
            di <b className="text-black">sospensione dei pagamenti</b> della FWU
            Life Insurance Lux; tale sospensione{" "}
            <b className="text-black">potrà durare al massimo 6 mesi.</b>
          </p>
          <p className="mt-16">
            A partire dal <b className="text-black">27 settembre 2024</b>, FWU
            Life Insurance Lux S.A. (“FLL”) sta{" "}
            <b className="text-black">ripristinando</b> progressivamente il
            regolare processo di investimento.
          </p>
          <p className="mb-5">
            Di seguito l'informativa rilasciata da FWU Life in data 10 ottobre
            2024:
          </p>
          <a
            className="cursor-pointer text-blue-700 font-semibold"
            href="https://assets.ctfassets.net/2novtaen2sie/5SaI6iqDVU2KjZpLW4Pp7I/cec890acc673dd34abebbee86a83bcd3/241010_Unsolvability_letter_Policyholders_it.pdf"
            target="_blank"
          >
            Vedi Documento
          </a>
          <p className="mt-10">
            Il <b className="text-black">22 gennaio 2025</b> CAA informa che ha presentato una domanda di scioglimento e liquidazione giudiziaria contro la società presso il Tribunal d’arrondissement de Luxembourg.
          </p>
          <div className="md:px-20">
            <p className="text-black text-start text-lg sm:text-xl font-bold mt-10 mb-5 sm:mt-12 -ms-5">
              Di seguito alcuni comunicati stampa:
            </p>
            <ul className="text-start space-y-5">
              <li className="list-disc">
                <a
                  className="text-blue-700 hover:underline"
                  href="https://www.caa.lu/uploads/documents/files/FWU_Elements_suite_liquidation_20250205.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.caa.lu/uploads/documents/files/FWU_Elements_suite_liquidation_20250205.pdf
                </a>
              </li>
              <li className="list-disc">
                <a
                  className="text-blue-700 hover:underline"
                  href="https://www.caa.lu/uploads/documents/files/FWU_Echec_plan_retablissement_20250122.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.caa.lu/uploads/documents/files/FWU_Echec_plan_retablissement_20250122.pdf
                </a>
              </li>
              <li className="list-disc">
                <a
                  className="text-blue-700 hover:underline"
                  href="https://www.ivass.it/media/comunicati/documenti/2024/ivcs679.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.ivass.it/media/comunicati/documenti/2024/ivcs679.pdf
                </a>
              </li>
              <li className="list-disc">
                <a
                  className="text-blue-700 hover:underline"
                  href="https://www.ivass.it/consumatori/azioni-tutela/vigilanza-imprese-estere/fwu/20240820_Gruppo_FWU_aggiornamenti_CAA.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.ivass.it/consumatori/azioni-tutela/vigilanza-imprese-estere/fwu/20240820_Gruppo_FWU_aggiornamenti_CAA.pdf
                </a>
              </li>
              <li className="list-disc">
                <a
                  className="text-blue-700 hover:underline"
                  href="https://www.caa.lu/fr/consommateurs/insolvabilite-de-fwu-life-insurance-lux-sa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.caa.lu/fr/consommateurs/insolvabilite-de-fwu-life-insurance-lux-sa
                </a>
              </li>
              <li className="list-disc">
                <a
                  className="text-blue-700 hover:underline"
                  href="https://www.caa.lu/uploads/documents/files/Sursis_de_paiement_de_FWU_Life_20240802.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.caa.lu/uploads/documents/files/Sursis_de_paiement_de_FWU_Life_20240802.pdf
                </a>
              </li>
              <li className="list-disc">
                <a
                  className="text-blue-700 hover:underline"
                  href="https://www.eiopa.europa.eu/eiopa-provides-initial-information-policyholders-affected-fwu-ags-insolvency-2024-08-19_en?prefLang=it"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.eiopa.europa.eu/eiopa-provides-initial-information-policyholders-affected-fwu-ags-insolvency-2024-08-19_en?prefLang=it
                </a>
              </li>
            </ul>
          </div>
          <p className="text-black text-xl mt-10 mb-5">
            <b>
              Le autorità competenti (IVASS, EIOPA e l'Autorità lussemburghese
              CAA) stanno seguendo la situazione
            </b>{" "}
            in stretto contatto tra di loro.
          </p>
          <p className="text-black text-xl mb-10">
            <b>
              Eventuali aggiornamenti e comunicati stampa sono disponibili ai
              seguenti link:
            </b>
          </p>
          <div className="md:px-20">
            <ul className="text-start space-y-5">
              <li className="list-disc">
                <a
                  className="text-blue-700 hover:underline"
                  href="https://www.ivass.it/media/comunicati/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.ivass.it/media/comunicati/index.html
                </a>
              </li>
              <li className="list-disc">
                <a
                  className="text-blue-700 hover:underline"
                  href="https://www.caa.lu/fr/consommateurs/infos-consommateurs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.caa.lu/fr/consommateurs/infos-consommateurs
                </a>
              </li>
              <li className="list-disc">
                <a
                  className="text-blue-700 hover:underline"
                  href="https://www.eiopa.europa.eu/media/news_en?prefLang=it"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.eiopa.europa.eu/media/news_en?prefLang=it
                </a>
              </li>
            </ul>
          </div>
          <hr className="mb-5 mt-10"></hr>
          <div className="flex flex-col justify-center items-center space-y-2">
            <p>Per eventuali informazioni:</p>
            <a className="" href="tel:0458213155">
              Tel.: <span className="text-blue-700">045 8213155</span>
            </a>
            <a className="" href="mailto:info@carefidelity.it">
              E-mail:{" "}
              <span className="text-blue-700">info@carefidelity.it</span>
            </a>
          </div>
          <hr className="my-5"></hr>
          <p className="text-4xl font-semibold text-black mt-10 mb-5">
            Chi Siamo
          </p>
          <p>
            OBIETTIVO: analizzare le tue esigenze, proponendoti le migliori
            soluzioni per il tuo benessere e il tuo futuro.
          </p>
          <p className="mt-5">
            Care Fidelity, divisione del Gruppo Care Holding SpA, iscritta in
            sezione A del RUI al nr. A000396928, è composta da professionisti in
            grado di fornire una consulenza globale rispondendo alle tue domande
            e necessità in modo professionale, chiaro e completo.
          </p>
          <p className="mt-5">
            <b>
              PRENDERCI CURA DEL TUO BENESSERE FINANZIARIO È NELLA NOSTRA NATURA
            </b>
          </p>
          <hr className="my-5"></hr>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
